.inventoryDetails {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  padding: 2rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    button {
      margin: 0;
    }

    h3 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  .content {
    .topContentContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: .75rem;
    }

    .imageContainer {
      float: right;
      height: 0;
      .imageWrapper {
        height: 104px;
        width: 104px;
        position: relative;
        top: 0;
        right: 0;

        img {
          width: 100%;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul li,
    .conditionsAtOrigin {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;

      strong {
        margin-right: 0.25rem;
        font-weight: 600;
      }
    }
  }
}