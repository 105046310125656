.addressTable {
  th {
    width: 6rem;
  }

  th,
  td {
    vertical-align: top;
  }
}
