.claimFormSubmission {

  .listGrid {
    section {
      font-size: 0.875rem;
      line-height: 1.375rem;
      header {
        font-weight: 700;
        margin: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .listGrid {
      display: grid;
      grid-template-areas:
    "a b"
    "c d"
    "c e"
    "c f"
    "c g";

      .damageDescription {
        grid-area: c;
      }
    }
  }

  @media (max-width: 768px) {
    .listGrid {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
