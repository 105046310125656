.badge {
  background: #f2f8fc;
  border: 0.5px solid #7db8e5;
  box-sizing: border-box;
  border-radius: 30px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #0069a0;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  display: inline-block;

  &.blue {
    color: #0069a0;
    background: #f2f8fc;
    border-color: #7db8e5;
  }

  &.yellow {
    color: #806420;
    background: #fdf2d1;
    border-color: #806420;
  }

  &.green {
    color: #2a552a;
    background: #d9ecdb;
    border-color: #2a552a;
  }

  &.grey {
    color: #4d4d4d;
    background: #e5e5e5;
    border-color: #4d4d4d;
  }

  &.red {
    color: #692326;
    background: #f3d8da;
    border-color: #692326;
  }
}
