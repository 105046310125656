table.table {
  min-width: 200px;
  width: 100%;

  thead > tr,
  tbody > tr {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 4fr;
  }

  th {
    padding: 1rem 0;
    color: #808080;
  }

  thead {
    tr {
      border-bottom: 1px solid #e5e5e5;
    }

    th:not(:first-child) {
      text-align: center;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e5e5e5;

      th {
        padding: 1.5rem 0;
        color: #01314a;
      }

      td {
        padding: 1.5rem 0;
      }
    }

    .heavy_cell {
      padding-top: 1.25rem;
      text-align: center;
    }

    .removed_cell {
      text-align: center;
      line-height: 0.875rem;
    }

    .quantity_cell {
      display: flex;
      justify-content: center;
      padding: 0;
      padding-top: 0.42rem;
    }
  }
}
