.userDetailsCard {
  display: grid;
  grid-template-columns: fit-content(10%) auto;
  grid-template-areas:
    "a b"
    "a c";

  .insignia {
    grid-area: a;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
