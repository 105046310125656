.entitlements {
  //Breakpoint - XLarge
  @media (min-width: 1441px) {
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 6rem;
  }

  //Breakpoint - Large
  @media (max-width: 1440px) {
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 3rem;
  }

  //Breakpoint - Medium
  @media (max-width: 1024px) {
    grid-column-gap: 1rem;
    grid-template-columns: 6fr 6fr;
  }

  //Breakpoint - Small
  @media (min-width: 769px) {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
  }
  @media (max-width: 768px) {
    display: block;
  }

  //Grid Layout
  .header {
    grid-row: 1;
  }

  .entitlementCard {
    grid-row: 2;
    grid-column: 1;
  }

  .legendCard {
    grid-row: 3;
    grid-column: 1;
  }

  .detailsList {
    grid-row: 2 / span 3;
  }
}

@media (min-width: 769px) and (min-height: 950px) {
  .legendCard {
    position: sticky;
    top: 2rem;
    align-self: start;
  }
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 1.5rem;
  }
}
